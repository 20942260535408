import React from 'react';
import { Box, Typography } from '@mui/material';
import { CustomButton, Flex, ResponsiveBreak } from '../../../../../components';

import startImage from '../../../../../assets/passing-star.png';
import { ReactComponent as CalendlyIcon } from '../../../../../assets/calendly-icon.svg';
import ReachOutToUs from '../ReachOutToUs';
import { logEvent } from 'src/services';

interface IProps {
  calendlyLink: string | null;
  isCalendlyLoading: boolean;
  setShowCalendlyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoTestTasksMessage: React.FC<IProps> = ({
  calendlyLink,
  isCalendlyLoading,
  setShowCalendlyModal,
}) => {
  const isShowCalendlyLink = typeof calendlyLink === 'string';

  return (
    <>
      <Flex flexDirection="column" justifyContent="space-between">
        <Typography variant="h2" mb={0} data-testid="success-title">
          Thank you <ResponsiveBreak breakpoint="xs" />
          for your application!
        </Typography>

        <Box
          component="img"
          src={startImage}
          alt="Star"
          width={{ xs: 57, sm: 67, xxl: 100 }}
          height={{ xs: 57, sm: 67, xxl: 100 }}
        />
      </Flex>
      <Typography
        textAlign="center"
        variant="body1"
        data-testid="success-text"
        width="100%"
      >
        {isShowCalendlyLink ? (
          <>
            Our Talent Executive would be happy{' '}
            <ResponsiveBreak breakpoint="sm" />
            to have an intro call with you.
          </>
        ) : (
          <>
            Our Talent Executive will reach out{' '}
            <ResponsiveBreak breakpoint="md" />
            to you to discuss the next steps.
          </>
        )}
      </Typography>
      {isShowCalendlyLink && (
        <>
          <Box my={1} data-testid="calendly-button">
            <CustomButton
              color="primary"
              startIcon={
                <Flex bgcolor="white" borderRadius={2} p={0.5}>
                  <CalendlyIcon />
                </Flex>
              }
              label="Book a time slot"
              onClick={() => {
                logEvent('launchpod-book-time-slot-prescreen-call-clicked');
                setShowCalendlyModal && setShowCalendlyModal(true);
              }}
              isLoading={isCalendlyLoading}
            />
          </Box>
          <ReachOutToUs />
        </>
      )}
    </>
  );
};

export default NoTestTasksMessage;
