import React, { useState, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  PageTransitionWrapper,
  FileInput,
  GoToNextStep,
} from '../../components';
import { usePersistUserAnswers, useDebounce } from '../../hooks';
import { LINKEDIN_REGEXP, LINKEDIN_ERROR_MESSAGE } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux';
import { updateProfessionalIdentity, userSelectors } from '../../redux/user';
import {
  gamifiedResponseSlice,
  gamifiedResponseSelectors,
} from '../../redux/gamifiedResponse';
import { IProfessionalIdentityBody, ZohoCandidateTypes } from '../../types';
import { getUserPrefferedName } from '../../utils';
import GoToPreviousStep from '../../components/goToPreviousStep/GoToPreviousStep';
import { logEvent } from 'src/services';

const isValidLinkedinURL = (url: string | null): boolean =>
  !url || LINKEDIN_REGEXP.test(url);

const ProfessionalIdentity: React.FC = () => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userSelectors.getUserData)!;
  const isLoading = useAppSelector(userSelectors.getIsUserPerformingAction);
  const lastGamifiedMessage = useAppSelector(
    gamifiedResponseSelectors.getGamifiedResponse,
  );
  const [savedProfessionalId, setSavedProfessionalId] =
    usePersistUserAnswers<IProfessionalIdentityBody>('identityStep');
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [professionalIdentity, setProfessionalIdentity] =
    useState<IProfessionalIdentityBody>({
      linkedinLink: savedProfessionalId?.linkedinLink || userData?.linkedinLink,
      originalCvFileName: (userData?.originalCvFileName as File) ?? null,
    });
  const [linkedinLinkError, setLinkedinLinkError] = useState('');
  const [cvFileError, setCVFileError] = useState('');

  const isLinkedInVisible =
    userData?.zohoUserType !== ZohoCandidateTypes.ExistingSignedToLpWithZohoId;
  const isDisabledSubmit =
    (professionalIdentity.linkedinLink &&
      !isValidLinkedinURL(professionalIdentity.linkedinLink)) ||
    !professionalIdentity.originalCvFileName ||
    !!cvFileError;

  useEffect(() => {
    if (userData.originalCvFileName) {
      dispatch(gamifiedResponseSlice.actions.updateMessage(null));
    } else if (!lastGamifiedMessage) {
      dispatch(
        gamifiedResponseSlice.actions.updateMessage(
          `Welcome back, ${getUserPrefferedName(userData)}!`,
        ),
      );
    }

    logEvent('launchpod-professional-identity-page-loaded');
  }, []);

  const debouncedClearEmptyLinkedIn = useDebounce(() => {
    setProfessionalIdentity((prevState) => ({
      ...prevState,
      linkedinLink: null,
    }));
  }, 1000);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name, files } = event.target;

    if (name === 'linkedinLink') {
      if (!value.trim()) {
        debouncedClearEmptyLinkedIn();
      }
    }

    setProfessionalIdentity((prevState) => ({
      ...prevState,
      [name]: name === 'originalCvFileName' ? files![0] : value,
    }));
    setSavedProfessionalId({ ...professionalIdentity, [name]: value });
  };

  const handleSubmit = (e: React.SyntheticEvent | null): void => {
    if (e) {
      e.preventDefault();
    }

    if (isDisabledSubmit) return;

    logEvent('launchpod-professional-identity-continue-click');

    dispatch(
      gamifiedResponseSlice.actions.updateMessage(
        'Awesome! No more questions.',
      ),
    );
    dispatch(
      updateProfessionalIdentity({
        ...professionalIdentity!,
        onUploadProgress,
      }),
    );
  };

  const onUploadProgress = (e: ProgressEvent) => {
    const { loaded, total } = e;
    const progress = Math.round((100 * loaded) / total);
    setUploadProgress(progress);
  };

  const onBlurLinkedinInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = isValidLinkedinURL(value);

    if (value && !isValid) {
      setLinkedinLinkError(LINKEDIN_ERROR_MESSAGE);
    }
  };

  return (
    <PageTransitionWrapper>
      <Box display="flex" data-testid="professional-identity-layout">
        <GoToPreviousStep />
        <Typography variant="h2" mb={2}>
          Do you want to showcase more{' '}
          <Box
            component="br"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          />{' '}
          of your professional identity?
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={2}
          my={2}
          sx={(theme) => ({
            maxWidth: '24rem',
            [theme.breakpoints.down('sm')]: {
              maxWidth: 'initial',
            },
          })}
        >
          <Typography variant="body1" color="primary" sx={{ fontSize: 20 }}>
            Resume
          </Typography>
          <FileInput
            label="Attach resume"
            hint="PDF or DOC, 20 MB"
            name="originalCvFileName"
            onChange={handleChange}
            value={[
              (professionalIdentity.originalCvFileName as File)?.name ||
                (professionalIdentity.originalCvFileName as string) ||
                '',
            ]}
            loading={true}
            progress={uploadProgress}
            setErrorInFile={(errors) => setCVFileError(errors[0])}
            error={{ 0: cvFileError }}
            autoFocus
          />
          {isLinkedInVisible && (
            <Box>
              <Typography
                variant="body1"
                sx={{ fontSize: 20, margin: '24px 0 16px' }}
              >
                LinkedIn
              </Typography>
              <Box component={'ul'} sx={() => ({ fontSize: 13, mb: 2, p: 0 })}>
                <Box>1. Helps us better understand your professional life</Box>
                <Box>
                  2. Shares more insights on your career and personality
                </Box>
              </Box>
              <TextField
                name="linkedinLink"
                type="text"
                label="LinkedIn profile"
                placeholder="https://www.linkedin.com/in/your-profile"
                variant="outlined"
                fullWidth
                value={professionalIdentity.linkedinLink ?? ''}
                onChange={handleChange}
                onFocus={() => setLinkedinLinkError('')}
                onBlur={onBlurLinkedinInput}
                error={!!linkedinLinkError}
                helperText={linkedinLinkError}
                InputProps={{
                  endAdornment: professionalIdentity.linkedinLink ? null : (
                    <InputAdornment position="end">
                      <Typography variant="body2" color="secondary">
                        optional
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                autoFocus={false}
              />
            </Box>
          )}
        </Stack>
      </form>
      <GoToNextStep
        isLoading={isLoading}
        isDisabled={isDisabledSubmit}
        textIdentifier={4}
        handleSubmitStep={() => handleSubmit(null)}
      />
    </PageTransitionWrapper>
  );
};

export default ProfessionalIdentity;
