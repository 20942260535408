import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { useAppSelector } from '../../redux';
import {
  useGamifiedMessage,
  useIsDisqualified,
  useUserMeetings,
} from '../../hooks';

import { PageTransitionWrapper } from '../../components';
import { ScheduleCallLayout } from './components/scheduleCallLayout/ScheduleCallLayout';
import FailedTtLayout from './components/failedTtLayout/FailedTtLayout';
import { DisqualifiedLayout } from './components/disqualifiedLayout/DisqualifiedLayout';
import SuccessLayout from './components/successLayout/SuccessLayout';

import { userSelectors } from '../../redux/user';

import { logEvent } from 'src/services';

const ApplicationSuccess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { updateGamifiedMessage } = useGamifiedMessage();

  const userData = useAppSelector(userSelectors.getUserData);
  const { activeIntroCall } = useUserMeetings();
  const { isDisqualified, isFailedTestTask } = useIsDisqualified();

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      navigate(1);
      return;
    }
  }, [location]);

  useEffect(() => {
    logEvent('launchpod-application-success-page-loaded');
    updateGamifiedMessage(null);
  }, []);

  const Layout = useMemo(() => {
    if (activeIntroCall && !userData?.closedAt) return ScheduleCallLayout;

    if (isFailedTestTask) return FailedTtLayout;

    if (isDisqualified) return DisqualifiedLayout;

    return SuccessLayout;
  }, [userData?.closedAt, isFailedTestTask, isDisqualified, activeIntroCall]);

  return (
    <PageTransitionWrapper data-testid="application-success-layout">
      <Layout />
    </PageTransitionWrapper>
  );
};

export default ApplicationSuccess;
