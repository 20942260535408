import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { useUserMeetings, useUserTestTasks } from '../../../../hooks';

import { Box } from '@mui/material';
import { StepPanel } from '../../../../components';
import { PopupModal } from 'react-calendly';

import CustomTestTaskMessage from './messages/CustomTestTaskMessage';
import TestTaskPassedMessage from './messages/TestTaskPassedMessage';
import NoTestTasksMessage from './messages/NoTestTasksMessage';

import { fetchUser, userSelectors } from '../../../../redux/user';
import { calendlySelectors, getCalendlyLink } from '../../../../redux/calendly';
import { getActiveUserMeetings } from '../../../../redux/meeting';

import {
  isStatusComplete,
  isStatusIdle,
  isStatusLoading,
  pollRequest,
} from '../../../../utils';

import { CALENDLY_UTM_SOURCE } from '../../../../constants/calendly';
import { IMeeting, MeetingType } from '../../../../types';
import { TestTaskType } from 'src/constants';

const ACTIVE_MEETINGS_POLL_INTERVAL = 10;
const ACTIVE_MEETINGS_POLL_ATTEMPTS = 3;

const SuccessLayout = () => {
  const dispatch = useAppDispatch();

  const { availableTestTasks, currentTestTask, passedTestTasks } =
    useUserTestTasks();
  const { activeIntroCall, activeUserMeetingsAPIStatus } = useUserMeetings();
  const userData = useAppSelector(userSelectors.getUserData);
  const calendlyLink = useAppSelector(calendlySelectors.getCalendlyLink);
  const calendlyApiStatus = useAppSelector(calendlySelectors.getApiStatus);

  const isWithoutTestTasks = !availableTestTasks?.length;
  const isCustomTestTask =
    currentTestTask?.testTaskType === TestTaskType.CUSTOM;
  const latestPassedTestTask = passedTestTasks?.[0];
  const isPreviousTestTask =
    !!userData?.profileSubmittedAt &&
    !!latestPassedTestTask?.resultDate &&
    userData?.profileSubmittedAt > latestPassedTestTask?.resultDate;

  const [isCallBooked, setIsCallBooked] = useState(false);
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  const getUserActiveMeetings = useCallback(() => {
    return dispatch(getActiveUserMeetings()).unwrap();
  }, []);

  const pollUserActiveMeetings = () => {
    pollRequest<IMeeting[] | null>({
      fn: getUserActiveMeetings,
      validate: (value) => {
        const ic = value?.find((e) => e.type === MeetingType.INTRO_CALL);

        return ic?.id !== activeIntroCall?.id;
      },
      interval: ACTIVE_MEETINGS_POLL_INTERVAL * 1000,
      maxAttempts: ACTIVE_MEETINGS_POLL_ATTEMPTS,
    });
  };

  const checkForCalendlySuccess = useCallback((e: MessageEvent) => {
    if (
      e.origin === 'https://calendly.com' &&
      e.data?.event === 'calendly.event_scheduled' &&
      e.data?.payload?.invitee?.uri
    ) {
      setShowCalendlyModal(false);
      setIsCallBooked(true);
    }
  }, []);

  useEffect(() => {
    if (isStatusIdle(calendlyApiStatus)) dispatch(getCalendlyLink());
  }, [calendlyApiStatus]);

  useEffect(() => {
    window.addEventListener('message', checkForCalendlySuccess);
    return () => {
      window.removeEventListener('message', checkForCalendlySuccess);
    };
  }, [checkForCalendlySuccess]);

  useEffect(() => {
    if (
      isCallBooked &&
      !activeIntroCall &&
      isStatusComplete(activeUserMeetingsAPIStatus)
    ) {
      pollUserActiveMeetings();
      dispatch(fetchUser());
    } else if (activeIntroCall) {
      dispatch(fetchUser());
    }
  }, [activeIntroCall, isCallBooked, activeUserMeetingsAPIStatus]);

  return (
    <>
      <Box>
        <StepPanel
          {...(isPreviousTestTask && { maxWidth: '36rem' })}
          {...(isWithoutTestTasks && {
            alignItems: 'center',
            textAlign: 'center',
          })}
        >
          {isWithoutTestTasks ? (
            <NoTestTasksMessage
              calendlyLink={calendlyLink}
              isCalendlyLoading={isStatusLoading(calendlyApiStatus)}
              setShowCalendlyModal={setShowCalendlyModal}
            />
          ) : isCustomTestTask ? (
            <CustomTestTaskMessage
              calendlyLink={calendlyLink}
              isCalendlyLoading={isStatusLoading(calendlyApiStatus)}
              setShowCalendlyModal={setShowCalendlyModal}
            />
          ) : (
            <TestTaskPassedMessage
              calendlyLink={calendlyLink}
              isCalendlyLoading={isStatusLoading(calendlyApiStatus)}
              setShowCalendlyModal={setShowCalendlyModal}
              isPreviousTestTask={isPreviousTestTask}
            />
          )}
        </StepPanel>
      </Box>
      {calendlyLink && (
        <PopupModal
          url={calendlyLink}
          prefill={{
            email: userData?.email,
            name: `${userData?.firstName} ${userData?.lastName}`,
            firstName: userData?.firstName as string,
            lastName: userData?.lastName as string,
          }}
          onModalClose={() => setShowCalendlyModal(false)}
          open={showCalendlyModal}
          rootElement={document.getElementById('root')!}
          utm={{
            utmContent: userData?.id,
            utmSource: CALENDLY_UTM_SOURCE,
          }}
        />
      )}
    </>
  );
};

export default SuccessLayout;
