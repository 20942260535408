import { useAppSelector } from '../redux';
import { featureFlagsSelectors } from '../redux/featureFlags';
import { API_STATUS, FEATURE_FLAG_KEYS } from '../constants';

export const useFeatureFlags = (): Record<string, boolean | API_STATUS> => {
  const featureFlags = useAppSelector(featureFlagsSelectors.getFeatureFlags);
  const featureFlagsAPIStatus = useAppSelector(
    featureFlagsSelectors.getFeatureFlagsApiStatus,
  );

  const showNicknameField =
    !!featureFlags[FEATURE_FLAG_KEYS.NAME_STEP_WITH_NICKNAME];

  const isTTSelectionPageSimplified =
    !!featureFlags[FEATURE_FLAG_KEYS.TT_SELECTION_PAGE_SIMPLIFIED];

  return {
    featureFlagsAPIStatus,
    showNicknameField,
    isTTSelectionPageSimplified,
  };
};
