import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calendlyStoreKey } from './calendly.const';
import { getCalendlyLink } from './calendly.thunks';
import { ICalendlyData } from '../../types';
import { API_STATUS } from '../../constants';

export interface ICalendlySliceState {
  apiStatus: API_STATUS;
  data: ICalendlyData | null;
}

const initialState: ICalendlySliceState = {
  apiStatus: API_STATUS.IDLE,
  data: null,
};

export const calendlySlice = createSlice({
  name: calendlyStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCalendlyLink.pending, (state) => {
        state.apiStatus = API_STATUS.LOADING;
      })
      .addCase(
        getCalendlyLink.fulfilled,
        (state, action: PayloadAction<ICalendlyData | null>) => {
          state.apiStatus = API_STATUS.COMPLETE;
          state.data = action.payload;
        },
      )
      .addCase(getCalendlyLink.rejected, (state) => {
        state.apiStatus = API_STATUS.FAILED;
      });
  },
});
