import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { calendlyStoreKey } from './calendly.const';
import { calendlyLink } from '../../constants/apiEndpoints';
import { formatErrorMessage } from '../../utils';
import { notificationAlert } from '../notifications';

export const getCalendlyLink = createAsyncThunk(
  `${calendlyStoreKey}/getCalendlyLink`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(calendlyLink());
      return response.data || null;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
