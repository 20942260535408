import { Box, Typography } from '@mui/material';

const ReachOutToUs = () => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" lineHeight={1.75}>
        Don't see an open slot?
      </Typography>
      <Typography variant="body1" color="text.secondary" mt={2}>
        Reach out to us in chat
        <br />
        to book an extra slot 💬
      </Typography>
    </Box>
  );
};

export default ReachOutToUs;
