import React from 'react';
import {
  AutocompleteRenderInputParams,
  Box,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { ICountry } from '../../../types';

interface IProps {
  params: AutocompleteRenderInputParams;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  countrySelected: ICountry | null;
  locationIsDetected: boolean;
  isSelected?: boolean | false;
}

const SelectableTextField = styled(TextField, {
  shouldForwardProp: (prop) => !['isSelected'].includes(String(prop)),
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  background: isSelected
    ? theme.palette.highlight.accent
    : theme.palette.highlight.white,
}));

const AutocompleteTextField: React.FC<IProps> = ({
  params,
  inputRef,
  countrySelected,
  locationIsDetected,
  isSelected = false,
}) => {
  return (
    <SelectableTextField
      {...params}
      isSelected={isSelected}
      inputRef={inputRef}
      placeholder="Select other country"
      autoComplete="off"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <SearchIcon
            sx={(theme) => ({
              marginLeft: '1rem',
              color: theme.palette.text.secondary,
            })}
          />
        ),
        endAdornment: (
          <>
            {countrySelected?.name && isSelected && locationIsDetected && (
              <Box display="flex" alignItems="center" mr={-2}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={(theme) => ({
                    [theme.breakpoints.down('lg')]: {
                      display: 'none',
                    },
                  })}
                >
                  Based on your location
                </Typography>
                <MyLocationIcon
                  fontSize="small"
                  sx={(theme) => ({
                    marginLeft: '0.5rem',
                    color: theme.palette.text.secondary,
                  })}
                />
              </Box>
            )}
            {params.InputProps.endAdornment}
          </>
        ),
        sx: { fontWeight: '600' },
      }}
    />
  );
};

export default AutocompleteTextField;
